var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-10 table-margin",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"no-data-text":"Nenhum periodo de conciliação encontrado","footer-props":{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${_vm.pagination.page} de ${
                    parseInt(_vm.totalItems / _vm.pagination.itemsPerPage) + 1
                }`,
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.start_date`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.start_date)))])]}},{key:`item.finish_date`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.finish_date)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"black"},on:{"click":function($event){return _vm.searchRegistrysByPeriod({ item: item })}}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])]}}],null,true)},[_c('span',[_vm._v("Buscar nesse período")])])]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }