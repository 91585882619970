<template>
    <div>
        <v-card>
            <v-data-table
                :headers="headers"
                :loading="loading"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                class="elevation-10 table-margin"
                no-data-text="Nenhum periodo de conciliação encontrado"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100],
                    'items-per-page-text': 'Itens por página',
                    'show-first-last-page': true,
                    'page-text': `Página ${pagination.page} de ${
                        parseInt(totalItems / pagination.itemsPerPage) + 1
                    }`,
                }"
            >
                <template v-slot:[`item.start_date`]="{ item }">
                    <span>{{ getDate(item.start_date) }}</span>
                </template>
                <template v-slot:[`item.finish_date`]="{ item }">
                    <span>{{ getDate(item.finish_date) }}</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                color="black"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                                @click="searchRegistrysByPeriod({ item: item })"
                                >mdi-magnify</v-icon
                            >
                        </template>
                        <span>Buscar nesse período</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodsConciliationDialog',
    computed: {
        ...mapState('period_conciliation', [
            'items',
            'notificationType',
            'notificationMessage',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
            'deleteDialog',
        ]),
        ...mapFields('periods_conciliation_dialog', ['pagination']),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Inicio', value: 'start_date', sortable: true },
            { text: 'Fim', value: 'finish_date', sortable: true },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '10%',
            },
        ],
        valid: false,
    }),
    methods: {
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        searchRegistrysByPeriod(item) {
            this.getRecordsByPeriod(item.item.id)
        },

        ...mapActions('period_conciliation', ['getItems']),
        ...mapActions('periods_conciliation_dialog', ['getRecordsByPeriod']),
    },
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false, pagination: this.pagination })
            },
        },
    },
}
</script>